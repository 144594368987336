<template>
    <div>
        <b-row> 
            <b-col lg="10" xl="8" offset-xl="2" offset-lg="1">

                <h1 class="mt-2 mb-4">{{trans('settings-complete-your-signature',167)}}</h1>

                <b-card class="mb-3">
                    <div class="app-card-profile-title mb-3">{{trans('settings-signature',167)}}</div>
                    <b-row>
                        <b-col class="app-local-signature">
                            <VueSignaturePad
                                id="signature"
                                width="100%"
                                height="300px"
                                ref="signaturePad"
                                :options="{
                                    onBegin: () => {
                                        $refs.signaturePad.resizeCanvas();
                                    },
                                    onEnd,
                                    penColor: '#393185'
                                }"
                            />
                        </b-col>
                    </b-row>
                </b-card>

                <div class="mt-4 mb-3" >
                    <!--<app-button type="primary" @click="saveStep(0)" type="cancel" :disabled="loading == 1"> {{trans('save',175)}} </app-button>-->
                    <app-button @click="clearSignature()" type="cancel">{{trans('settings-delete-signature',206)}}</app-button>
                    <app-button type="primary" @click="saveSignature()" :disabled="loading == true || is_empty" :loading="loading">{{trans('settings-save-signature',206)}}</app-button>
                </div>

            </b-col>
        </b-row>
    </div>
</template>
<script>

import axios from "@axios";


export default {

    props: ["idUser", "returnType", "returnIdType", "idUserSignature", "signatureJson"],

    created(){
        this.id_user = JSON.parse(localStorage.getItem('user')).id;
        this.signature_json = JSON.parse(localStorage.getItem('user')).signature_json;
    },

    mounted() {
        this.loadSignature()
    },
    
   data() {
        return {
            is_empty: true,
            loading: false,
            id_user: null,
        };
    },

    watch: {

    },

    methods: {

        clearSignature(){
            
            this.$refs.signaturePad.clearSignature();

            const { isEmpty } = this.$refs.signaturePad.saveSignature();

            if(isEmpty){
                this.is_empty = true;
            }else{
                this.is_empty = false;
            }
        },

        loadSignature(){
            if(JSON.parse(this.signature_json) != null && this.$route.params.idUserSignature == this.id_user){
                this.$refs.signaturePad.fromData(JSON.parse(this.signature_json));
            }else if(JSON.parse(this.$route.params.signatureJson) != null && this.$route.params.idUserSignature != this.id_user){
                this.$refs.signaturePad.fromData(JSON.parse(this.$route.params.signatureJson));
            }
        },

        saveSignature(){
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            var signature = data;
            
            this.cropImage(signature);

        },

        async cropImage(data){
            
            var img = new Image();

            await new Promise( resolve => {
                img.onload = () => {

                
                    var width = img.width;
                    var height = img.height;
                    
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    var a = ctx.getImageData(0,0, width, height);
                    var x,y;

                    var left = null;
                    var top = null;
                    var bottom = null;
                    var right = null;

                    var l = a.data.length;
                    for(var i = 0; i< l; i += 4){
                        if (a.data[i + 3] !== 0) {
                            x = (i / 4) % a.width;
                            y = ~~((i / 4) / a.width);

                            if (top === null) {
                                top = y;
                            }

                            if (left === null) {
                                left = x;
                            } else if (x < left) {
                                left = x;
                            }

                            if (right === null) {
                                right = x;
                            } else if (right < x) {
                                right = x;
                            }

                            if (bottom === null) {
                                bottom = y;
                            } else if (bottom < y) {
                                bottom = y;
                            }
                        }
                    }
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    var trimWidth = right - left;
                    var trimHeight = bottom - top;

                    canvas.width = 400;
                    canvas.height = 200;
                    var newWidth = 0;
                    var newHeight = 0;
                    if(trimWidth > canvas.width) {


                        newWidth = trimWidth > trimHeight ? canvas.width : ((trimWidth * canvas.height) / trimHeight);
                        newHeight = trimHeight > trimWidth ? canvas.height : ((trimHeight * canvas.width) / trimWidth);

                    } else if(trimWidth < canvas.width && trimHeight > canvas.height){
                        newHeight = canvas.height;
                        newWidth = ((trimWidth * canvas.height) / trimHeight);
                    } else {
                        newWidth = trimWidth;
                        newHeight = trimHeight;
                    }

                    ctx.drawImage(img, left, top, trimWidth, trimHeight, canvas.width/2 - newWidth/2, canvas.height/2 - newHeight/2, newWidth, newHeight);
                    

                    resolve(canvas.toDataURL());
                }

                img.src = data;
            }).then((res) => {
                this.save(res);
            });
            

        },

        save(signature){
            if(this.$route.params.returnType == 'user_card'){
                this.id_user = this.$route.params.idUserSignature;
            }
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

            let signature_json = this.$refs.signaturePad.toData();

            if(isEmpty == false && signature != null && this.id_user != null){
                
                this.loading = true;

                axios
                    .post("settings/users/profile/signature/change", {
                        id_user: this.id_user,
                        signature: signature,
                        signature_json: JSON.stringify(signature_json),
                    })
                    .then((res) => {
                        this.loading = false;

                        if(this.$route.params.idUserSignature == JSON.parse(localStorage.getItem('user')).id){
                            var user_data = JSON.parse(localStorage.getItem('user'));
                            user_data.signature = res.data.signature;
                            user_data.signature_date = res.data.signature_date;
                            user_data.signature_json = res.data.signature_json;
                            localStorage.setItem('user', JSON.stringify(user_data));
                        }

                        this.goBack();
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
            }
        },

        goBack(){

            if(this.$route.params.returnType == 'user_card'){
                this.$router.push({ name: "users-profile", params: { id_user: this.$route.params.returnIdType } });
            }else if(this.$route.params.returnType == 'profil_card'){
                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: this.$route.params.returnIdType } });
            }else if(this.$route.params.returnType == 'client_contract_wizard'){
                this.$router.push({ name: "clients-profile-contract-wizard", params: { id_client_data_main: this.$route.params.returnIdType } });
            }else{
                this.$router.push({ name: "users-profile", params: { id_user: this.$route.params.returnIdType } });
            }
        },

        goUserCard(){
            this.$router.push({ name: "users-profile", params: { id_user: this.$route.params.returnIdType} });  
        },

        onEnd(){
            const { isEmpty } = this.$refs.signaturePad.saveSignature();

            if(isEmpty){
                this.is_empty = true;
            }else{
                this.is_empty = false;
            }
        }

    }
 
};
</script>
<style>
    #signature {
        border: 1px dashed #BBE7FA;
        border-radius: 5px;
        position: relative;
        transition: 1s;
    }

  @media (-webkit-device-pixel-ratio: 1.1) {
    .app-local-signature {
      zoom: 1.1;
    }
  }

  @media (-webkit-device-pixel-ratio: 1.25) {
    .app-local-signature {
      zoom: 1.25;
    }
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    .app-local-signature {
      zoom: 1.5;
    }
  }


  @media (-webkit-device-pixel-ratio: 1.75) {
    .app-local-signature {
      zoom: 1.75;
    }
  }

  @media (-webkit-device-pixel-ratio: 2) {
    .app-local-signature {
      zoom: 2;
    }
  }
</style>